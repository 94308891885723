export const environment = {
  version: '7.3.1',
  production: false,
  restAPI: 'https://api.calenso.io/api/v1/',
  bookingWebcomponentUrl: 'https://webcomponent.calenso.io/booking.html',
  hostName: 'https://api.calenso.io',
  workerAvatarBaseUrl: 'https://api.calenso.io/webroot/files/workers/avatar/',
  storeBaseUrl: 'https://api.calenso.io/webroot/files/stores/logo/',
  deployUrl: 'https://smartwidget.calenso.io/',
  stripeAPIKey: 'pk_test_qC6Q2zbwLgHgHEzXkHdKATbR',
  encryptedKey: '6LfB5EchAAAAAMiJapMV9GumBAQBfKqboRWT5she'
};
